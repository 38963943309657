import React, { useState } from "react";
import GraphiQL from "graphiql";
import { useExplorerPlugin } from "@graphiql/plugin-explorer";
import schema from "./schema";
import "@graphiql/plugin-explorer/dist/style.css";
import "graphiql/graphiql.min.css";
import { buildSchema } from "graphql";
import { GraphiQLToolbarConfig } from "graphiql/dist/components/GraphiQL";
import CurlToolBarButton from "./toolbar/ curl-button";
import NodejsToolBarButton from "./toolbar/nodejs-button";
import PythonToolBarButton from "./toolbar/python-button";
import Logo from "./Logo";

GraphiQL.Logo = Logo;
const PreviewMessage = `# This API is under active development and subject to change.
# Feedback and suggestions are very welcome!
#
# Add your AUTH_TOKEN below, then click |> to execute the query.`;
const exampleQuery = `{
  myUser {
    organisation {
      name
      id
    }
  }
}`;
const defaultAuthHeader = `{
  "Authorization": "Bearer AUTH_TOKEN"
}
`;

const Ide = () => {
  const [query, setQuery] = useState(`${PreviewMessage}\n${exampleQuery}`);
  const explorerPlugin = useExplorerPlugin({
    query,
    onEdit: setQuery,
    showAttribution: false,
    hideActions: true,
    explorerIsOpen: true,
  });
  const toolbar: GraphiQLToolbarConfig = {
    additionalContent: [
      React.createElement(CurlToolBarButton, { key: "CurlToolBarButton" }),
      React.createElement(NodejsToolBarButton, { key: "NodejsToolBarButton" }),
      React.createElement(PythonToolBarButton, { key: "PythonToolBarButton" }),
    ],
  };
  return (
    <GraphiQL
      shouldPersistHeaders={true}
      defaultHeaders={`${defaultAuthHeader}`}
      schema={buildSchema(schema)}
      query={query}
      onEditQuery={setQuery}
      fetcher={async (graphQLParams, options) => {
        if (graphQLParams.operationName !== "IntrospectionQuery") {
          try {
            const data = await fetch(process.env.REACT_APP_GRAPHQL_URL!, {
              method: "POST",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                ...options?.headers,
              },
              body: JSON.stringify(graphQLParams),
              credentials: "same-origin",
            });
            return data.json().catch(() => data.text());
          } catch (error) {
            return {
              errors: [
                {
                  message: "Error happend while trying to fetch data.",
                },
              ],
            };
          }
        }
      }}
      toolbar={toolbar}
      plugins={[explorerPlugin]}
    ></GraphiQL>
  );
};

export default Ide;

import { ToolbarButton, useEditorContext } from "@graphiql/react";
import copyToClipboard from "copy-to-clipboard";
import React from "react";
import NodeJsLogo from './nodejs-small.svg';
import { clipboardNotify, getTokenFromHeaders } from "./helper";
import { ToastContainer } from "react-toastify";


const generateScript = (query: string, headers: string) => {
  const token = getTokenFromHeaders(headers);
  return `var axios = require('axios');
var data = JSON.stringify({
  query: \`${query}\`,
  variables: {}
});

var config = {
  method: 'post',
  url: '${process.env.REACT_APP_GRAPHQL_URL}',
  headers: { 
    'Authorization': '${token}', 
    'Content-Type': 'application/json'
  },
  data : data
};

axios(config)
.then(function (response) {
  console.log(JSON.stringify(response.data));
})
.catch(function (error) {
  console.log(error);
});
`;


};

const NodejsToolBarButton = () => {
  const { queryEditor, headerEditor } = useEditorContext({
    nonNull: true,
  });
  return (
    <ToolbarButton
      onClick={() => {
        const code = generateScript(
          queryEditor.getValue(),
          headerEditor.getValue()
        );
        copyToClipboard(code);
        clipboardNotify('Javascript code copied to clipboard!');
      }}
      label="Generate Node.js code"
    >
      <img height={32} src={NodeJsLogo} alt="Nodejs Logo" />
      <ToastContainer />
    </ToolbarButton>
  );
}

export default NodejsToolBarButton;
import { ToolbarButton, useEditorContext } from "@graphiql/react";
import copyToClipboard from "copy-to-clipboard";
import React from "react";
import PythonLogo from "./python.svg";
import { clipboardNotify, getTokenFromHeaders } from "./helper";
import { ToastContainer } from "react-toastify";

const generateScript = (query: string, headers: string) => {
  const token = getTokenFromHeaders(headers);
  return `import requests
import json

url = "${process.env.REACT_APP_GRAPHQL_URL}"

payload = {"query": """${query}"""}
headers = {
  'Authorization': '${token}',
  'Content-Type': 'application/json'
}

response = requests.request("POST", url, headers=headers, data=json.dumps(payload))

print(response.text)
`;
};

const PythonToolBarButton = () => {
  const { queryEditor, headerEditor } = useEditorContext({
    nonNull: true,
  });
  return (
    <ToolbarButton
      onClick={() => {
        const code = generateScript(
          queryEditor.getValue(),
          headerEditor.getValue()
        );
        copyToClipboard(code);
        clipboardNotify("Python code copied to clipboard!");
      }}
      label="Generate Python code"
    >
      <img height={32} src={PythonLogo} alt="Python Logo" />
      <ToastContainer />
    </ToolbarButton>
  );
};

export default PythonToolBarButton;
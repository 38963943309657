import { toast } from "react-toastify";

export const clipboardNotify = (message?: string) =>
  toast.info(message ?? "Copied to clipboard!", {
    position: "top-center",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
  });

export const getTokenFromHeaders = (headers: any) => {
  const headersObject = JSON.parse(headers);
  const token = headersObject["Authorization"] || "Bearer <APP_TOKEN>";
  return token;
};

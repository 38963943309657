import {
  ToolbarButton,
  useEditorContext,
} from "@graphiql/react";
import copyToClipboard from "copy-to-clipboard";
import React from "react";
import "react-toastify/dist/ReactToastify.css";
import { clipboardNotify, getTokenFromHeaders } from "./helper";
import { ToastContainer } from "react-toastify";

const generateCurl = (query: string, headers: string) => {
  const token = getTokenFromHeaders(headers);
  const reqQuery = query.replaceAll("\n", "\\n");
  return `curl -X POST \\
   -H "Authorization: ${token}" \\
   -H "Content-Type: application/json" \\
    ${process.env.REACT_APP_GRAPHQL_URL} \\
    --data-raw '{"query":"${reqQuery}"}'`;
};

const CurlToolBarButton = () => {
  const { queryEditor, headerEditor } = useEditorContext({
    nonNull: true,
  });


  return (
    <ToolbarButton
      onClick={() => {
        const code = generateCurl(
          queryEditor.getValue(),
          headerEditor.getValue()
        );
        copyToClipboard(code);
        clipboardNotify("Command copied to clipboard!");
      }}
      label="Generate CURL query"
    >
      <p style={{ color: "#9CA4B4" }}>CURL</p>
      <ToastContainer />
    </ToolbarButton>
  );
};

export default CurlToolBarButton;

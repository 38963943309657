import { Theme, useStorageContext } from "@graphiql/react";
import React, { PropsWithChildren } from "react";

function Logo<TProps>(props: PropsWithChildren<TProps>) {
  const { storage } = useStorageContext({ nonNull: true });
  let theme = storage?.getItem("graphiql:theme") as Theme;
  if (
    theme == null &&
    window.matchMedia("(prefers-color-scheme: dark)").matches
  ) {
    theme = "dark";
  }
  return (
    <div className="graphiql-logo">
      <a
        className="graphiql-logo-link"
        href="https://www.emnify.com/"
        target="_blank"
        rel="noreferrer"
      >
        <img
          src={theme === "dark" ? "./logo-white.svg" : "./logo.svg"}
          alt="Go to the emnify website"
        />
      </a>
    </div>
  );
}
Logo.displayName = "emnify";

export default Logo;
